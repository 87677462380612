/* You can add global styles to this file, and also import other style files */

@import "../node_modules/bootswatch/dist/cosmo/variables";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/bootswatch/dist/cosmo/bootswatch";
@import "./scss/breakspoints.scss";
@import "./scss/afu-upload.scss";
@import "./scss/widths.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "./styles/fonts.scss";

html,
body {
  height: 100%;
  background-color: white;
  margin: 0;
}

.container {
  max-width: 1440px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.container-print {
  padding-right: 45px !important;
  padding-left: 45px !important;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

*:active:focus {
  outline: none !important;
  box-shadow: none !important;
}

button:focus {
  box-shadow: none !important;
}
@media print {
  a {
    &:not(.btn) {
      text-decoration-line: none;
    }
  }
}

.noSelection {
  outline: 0 !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

.disabled {
  pointer-events: none !important;
  cursor: default !important;
}

.btn:disabled {
  opacity: 0.3;
}

.btn-1 {
  color: white;
  background-color: #ff5700;
  font-weight: bold !important;
}

.btn-2 {
  color: #ff5700;
  border-color: #ff5700;
  background-color: white;
}

.btn-2:hover {
  border-color: black;
}

.btn-3 {
  color: white;
  background-color: #ff5700;
}

.btn-3:hover {
  color: white;
  background-color: #d64900;
}

.btn-round {
  border-radius: 20px;
}

@media #{$phone-breakpoint} {
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.badge {
  border: none;
}

.next-page {
  background: white;
  width: 100%;
  height: 1px;
  display: block;
  page-break-after: always !important;
  page-break-inside: avoid !important;
}

.tooltip {
  left: 9px !important;
}

.nav-tabs {
  .nav-link.active {
    color: #ff5700 !important;
  }

  .nav-item {
    a {
      color: black;
    }
  }
}

.flex-center-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.badge-warning {
  font-size: 13px !important;
  font-weight: normal !important;
}

@media print {
  .container::-webkit-scrollbar {
    display: none !important;
  }

  html,
  body {
    font-family: "Arial";
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background: rgba(255, 87, 0, 0.5);
  color: white;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-optgroup.ng-option-selected.ng-option-marked {
  background: #ff5700;
  color: white;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  background-color: black;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 2px 10px;
  color: black;
}

.ng-dropdown-panel {
  display: none;
}

@media (min-width: 576px) {
  .modal-dialog.fsv {
    max-width: 680px;
  }
}

#inventory-modal {
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 450px;
    }
  }
}

#thumbnail-modal {
  .modal-dialog {
    min-height: unset !important;
    min-width: unset !important;
    width: calc(100% - 30px) !important;
    margin: 0 !important;
    transform: translateX(-50%) translateY(-50%) !important;
    top: 50% !important;
    left: 50% !important;
    max-width: calc(1410px) !important;
    height: calc(83vh - 30px) !important;

    @media #{$desktop} {
      height: calc(100vh - 30px) !important;
    }

    .modal-content {
      height: 100% !important;

      .modal-body {
        overflow: hidden;

        .row {
          height: 100%;

          .col-12 {
            height: 100%;

            .card-img-top {
              height: 100%;
              width: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }
    }
  }
}

#nsm-video-modal {
  .modal-dialog {
    min-height: unset !important;
    min-width: unset !important;
    width: calc(100% - 30px) !important;
    margin: 0 !important;
    transform: translateX(-50%) translateY(-50%) !important;
    top: 50% !important;
    left: 50% !important;
    max-width: calc(1410px) !important;
    height: calc(83vh - 30px) !important;

    @media #{$desktop} {
      height: calc(100vh - 30px) !important;
    }

    .modal-content {
      height: 100% !important;

      .modal-body {
        overflow: hidden;

        .row {
          height: 100%;

          .col-12 {
            height: 100%;

            iframe {
              width: 100%;
              height: 100%;
              border: none;
            }
          }
        }
      }
    }
  }
}

.hide {
  display: none;
}

.popover-body {
  color: #ff5700;
}

:root {
  --refWidth: 1920;
  --refHeight: 1080;
  --refMobileWidth: 375;
  --refMobileHeight: 760;
  --refMobileLandscapeWidth: 760;
  --refMobileLandscapeHeight: 375;
}

// TODO: Create proper theme files for all flavors
#wrapper {
  &.pbna {
    .btn {
      &:hover {
        color: lightgray;
      }
    }

    .btn-1 {
      background-color: black;
      border: white solid 1px;
    }

    .btn-2 {
      color: white;
      border-color: white;
      background-color: black;
    }
  }

  input::placeholder {
    color: black;
  }

  fc-home {
    .title {
      border-bottom-color: #000;
    }
  }

  fc-search {
    .form-control {
      color: black;
    }
  }

  &:not(.mcoe) {
    fc-menu-desktop {
      .inventory {
        a {
          color: white !important;
          border-color: #0047bb;
          background-color: #0047bb !important;

          &:hover {
            color: white !important;
            background-color: #0047bb !important;
          }
        }
      }
    }
  }

  fc-menu-desktop {
    .line,
    .line2 {
      border-color: #000;
    }
  }

  footer {
    .copyright {
      background: black;
    }
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/////////////////////////////////////////////////////////////////////////
/// Form related styles
.form-col {
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 16px;
  }
}

.form-row {
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 16px;
  }
}

mat-dialog-content {
  .processing {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.form-section {
  h5 {
    margin-bottom: 24px;
  }
}

.form-section + .form-section {
  margin-top: 32px;
}

/////////////////////////////////////////////////////////////////////////
/// Table related styles
.mat-mdc-no-data-row {
  .no-data {
    text-align: center;
    vertical-align: middle;
    font-size: 24px;
    height: 64px;

    .loading {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }
}

////////////////////////////
/// Tooltip related styles
.tooltip {
  white-space: pre-line;
}
