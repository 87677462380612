/////////////////////////////////////////////////////////////
/// ProximaNova
@font-face {
  font-family: "ProximaNova";
  src: url("../assets/fonts/ProximaNova-Regular.woff") format("woff");
}

/////////////////////////////////////////////////////////////
/// CircularStd
@font-face {
  font-family: "CircularStd";
  src: url("../assets/fonts/CircularStd-Book.woff") format("woff");
}

/////////////////////////////////////////////////////////////
/// Gotham
@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/Gotham-Book.woff") format("woff");
}

/////////////////////////////////////////////////////////////
/// Century Gothic
@font-face {
  font-family: "Century Gothic";
  src: url("../assets/fonts/CenturyGothic.woff") format("woff");
}

@font-face {
  font-family: "Century Gothic";
  src: url("../assets/fonts/CenturyGothicBold.woff") format("woff");
  font-weight: bold;
}

/////////////////////////////////////////////////////////////
/// Calibri
@font-face {
  font-family: "Calibri";
  src: url("../assets/fonts/calibri-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Calibri";
  src: url("../assets/fonts/calibri-italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Calibri";
  src: url("../assets/fonts/calibri-bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Calibri";
  src: url("../assets/fonts/calibri-bold-italic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
