@use "@angular/material" as mat;
@use "./mcoe-m3-theme" as m3-theme;

@include mat.core();

html {
  @include mat.core-theme(m3-theme.$light-theme);

  @include mat.button-theme(m3-theme.$light-theme);
  @include mat.checkbox-theme(m3-theme.$light-theme);
  @include mat.chips-theme(m3-theme.$light-theme);
  @include mat.dialog-theme(m3-theme.$light-theme);
  @include mat.expansion-theme(m3-theme.$light-theme);
  @include mat.form-field-theme(m3-theme.$light-theme);
  @include mat.icon-theme(m3-theme.$light-theme);
  @include mat.icon-button-theme(m3-theme.$light-theme);
  @include mat.input-theme(m3-theme.$light-theme);
  @include mat.paginator-theme(m3-theme.$light-theme);
  @include mat.progress-spinner-theme(m3-theme.$light-theme);
  @include mat.select-theme(m3-theme.$light-theme);
  @include mat.sort-theme(m3-theme.$light-theme);
  @include mat.table-theme(m3-theme.$light-theme);

  --mdc-filled-button-label-text-weight: 700;
  --mdc-filled-button-container-shape: 0;
  --mat-filled-button-hover-state-layer-opacity: 0;

  --mdc-outlined-button-label-text-weight: 700;
  --mdc-outlined-button-container-shape: 0;
  --mat-outlined-button-hover-state-layer-opacity: 0;
  --mdc-outlined-button-outline-color: mat.get-theme-color(
    m3-theme.$light-theme,
    primary
  );

  --mat-expansion-container-shape: 0;

  --mdc-dialog-container-shape: 4px;
  --mdc-dialog-container-color: #fff;

  button[mat-flat-button] {
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    &:hover {
      --mdc-filled-button-label-text-color: #000;
    }
  }

  button[mat-stroked-button] {
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    &:hover {
      --mdc-outlined-button-outline-color: #000;
    }
  }

  button[mat-icon-button] {
    --mdc-icon-button-icon-color: #{mat.get-theme-color(
        m3-theme.$light-theme,
        primary
      )};
  }

  .secondary-button {
    @include mat.button-color(m3-theme.$light-theme, $color-variant: secondary);
  }

  .tertiary-button {
    @include mat.button-color(m3-theme.$light-theme, $color-variant: tertiary);
  }

  .secondary-progress-spinner {
    @include mat.progress-spinner-color(
      m3-theme.$light-theme,
      $color-variant: secondary
    );
  }

  .mat-mdc-dialog-container .mat-mdc-dialog-title + .mat-mdc-dialog-content {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  mat-dialog-content {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .click-tooltip {
    position: relative;
    margin-bottom: 8px;
    background-color: #fff;
    font-size: 0.825rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-radius: 0.3rem;
    color: mat.get-theme-color(m3-theme.$light-theme, primary);

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 100%);
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: rgba($color: #000000, $alpha: 0.25) transparent transparent
        transparent;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 100%);
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 7.5px 7.5px 0 7.5px;
      border-color: #fff transparent transparent transparent;
    }
  }
}
