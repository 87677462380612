@import "src/scss/breakspoints.scss";

.pdf-handle,
.image-handle {
    .container#default {
        padding-left: 0;
        padding-right: 0;

        @media #{$phone-breakpoint} {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    }

    label {
        margin-bottom: 0;
    }

    .afu-select-btn,
    .afu-reset-btn,
    .afu-upload-btn {
        color: white;
        background-color: #ff5700;
        font-weight: bold !important;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 0.9375rem;
        line-height: 1.5;
        border-radius: 0;
    }

    .afu-select-btn,
    .afu-upload-btn {
        cursor: pointer;
    }

    .afu-valid-file {
        padding: 15px 15px 0px 0px;
        width: 100%;
    }

    .afu-reset-btn {
        margin-left: 16px !important;

        @media #{$phone-breakpoint} {
            margin-left: 0px !important;
            margin-top: 15px !important;
        }
    }

    .afu-constraints-info {
        display: none;
    }

    .textOverflow {
        margin-bottom: 0;
    }
}